import React, { useEffect, useState } from 'react';
import { Chip, Button, Typography, Paper, Box } from '@mui/material';
import axios from 'axios';
import { BASE_URL } from '../../api/Constants';

function ChromeCoinVerification() {
    const [tasks, setTasks] = useState([]);

    // Fetch tasks from the API
    const fetchTasks = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/api/chrome/task-ops/verification-tasks`, {
                params: {
                    status: 'pending', // We can customize the status here if needed
                }
            });
            if (response.status === 200) {
                setTasks(response.data.data.result); // Store fetched tasks
            }
        } catch (error) {
            console.error('Error fetching tasks:', error);
        }
    };

    useEffect(() => {


        fetchTasks();
    }, []);

    // Helper function to convert camelCase to Normal case
    const camelToNormal = (str) => {
        return str
            .replace(/([A-Z])/g, ' $1')
            .replace(/^./, (match) => match.toUpperCase());
    };

    const handleVerification = async (uid, taskId, status) => {
        try {
            const response = await axios.post(`${BASE_URL}/api/validateChromeCoin`, {
                uid,
                activityId: taskId,
                status,
            });

            if (response.status === 200) {
                // Handle success (update the UI, show a success message, etc.)
                console.log('Task verification response:', response.data);
                fetchTasks()
            }
        } catch (error) {
            console.error('Error verifying task:', error);
        }
    };

    // Helper function to check if an image URL exists
    const isValidImageUrl = (url) => {
        return url && (url.endsWith('.png') || url.endsWith('.jpg') || url.endsWith('.jpeg') || url.endsWith('.gif'));
    };

    return (
        <div style={{ padding: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', backgroundColor: '#f5f5f5' }}>
            <div style={{ width: '100%', maxWidth: '1200px' }}>
                <Typography variant="h4" align="center" gutterBottom>
                    Chrome Coin Verification Tasks
                </Typography>
                {tasks.length > 0 ? (
                    <Box display="flex" flexDirection="column" gap={3} paddingBottom="20px">
                        {tasks.map((task) => (
                            <Paper key={task._id} elevation={3} style={{ padding: '16px', borderRadius: '10px', backgroundColor: '#fff', minWidth: '300px' }}>
                                <Box textAlign="center" marginBottom="16px">
                                    {/* Show image if available */}
                                    {isValidImageUrl(task.imageURL) ? (
                                        <img
                                            src={task.imageURL}
                                            alt="Task Screenshot"
                                            style={{
                                                maxWidth: '100%',
                                                maxHeight: '200px', // Reduced height of the image
                                                objectFit: 'contain',
                                                borderRadius: '8px'
                                            }}
                                        />
                                    ) : (
                                        <Typography variant="body2" color="textSecondary">No image available</Typography>
                                    )}
                                </Box>

                                <Typography variant="h6" align="center" style={{ marginBottom: '12px' }}>
                                    {camelToNormal(task.activityType)}
                                </Typography>

                                <div style={{ marginBottom: '16px' }}>
                                    {Object.entries(task.details).map(([key, value]) => (
                                        <div key={key} style={{ marginBottom: '8px' }}>
                                            <Chip label={`${camelToNormal(key)}: ${value}`} style={{ marginRight: '8px' }} />
                                        </div>
                                    ))}
                                </div>

                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleVerification(task.uid, task._id, 'approved')}
                                        style={{ width: '48%', padding: '10px', fontSize: '16px' }}
                                    >
                                        Verify
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => handleVerification(task.uid, task._id, 'rejected')}
                                        style={{ width: '48%', padding: '10px', fontSize: '16px' }}
                                    >
                                        Reject
                                    </Button>
                                </Box>
                            </Paper>
                        ))}
                    </Box>
                ) : (
                    <Typography variant="h6" align="center" color="textSecondary">
                        No pending tasks found.
                    </Typography>
                )}
            </div>
        </div>
    );
}

export default ChromeCoinVerification;
