import React, { useEffect, useState } from 'react';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from '@mui/lab';
import { CircularProgress, Typography, Box } from '@mui/material';
import axios from 'axios';
import { BASE_URL } from '../../../../api/Constants';
import { format, formatDistanceToNow } from 'date-fns'; // For time ago format

function TicketAssignmentHistory({ id }) {
  const [assignmentHistory, setAssignmentHistory] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Function to fetch the assignment history
    const fetchAssignmentHistory = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/tickets/assingment-history?ticketID=${id}`);
        if (response.data.status === 200) {
          setAssignmentHistory(response.data.data.assignmentHistory);
        }
      } catch (error) {
        console.error('Error fetching assignment history:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAssignmentHistory();
  }, [id]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ margin: '20px', width: '100%', mt: '20px' }}>
      <Typography variant="h5" gutterBottom sx={{ textAlign: 'center', fontSize: '30px' }}>
        Ticket Assignment History
      </Typography>

      {
        assignmentHistory.length === 0 && <>
          <Typography variant="h5" gutterBottom sx={{ textAlign: 'center', fontSize: '16px' }}>
            No history found
          </Typography>
        </>
      }

      <Timeline position="alternate">
        {assignmentHistory.map((assignment, index) => (
          <TimelineItem key={index} sx={{ width: "100%" }}>
            <TimelineSeparator>
              <TimelineDot color="primary" />
              {index < assignmentHistory.length - 1 && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>
              <Typography variant="body1" color="textSecondary">
                <strong>{assignment.assignedBy}</strong> assigned to <strong>{assignment.assignedTo}</strong>
              </Typography>
              <Typography variant="caption" color="textSecondary">
                <strong>on {" "}</strong>
                <span>{format(new Date(assignment.time), 'dd MMM, yyyy')} at {new Date(assignment.time).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}</span>
                {' | '}
                <span>{formatDistanceToNow(new Date(assignment.time))} ago</span>
              </Typography>

            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </Box>
  );
}

export default TicketAssignmentHistory;
